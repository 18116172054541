.input-file{
    visibility: hidden;
}

#contenedorLogin{
    margin-top: 70px;
}

#formControl{
    display: inline-flex;
    width: 50%;
}

#alert{
    display: inline-block;
    width: 80%;
}

#loading{
    margin-left:20%;
    margin-right:20%;
   }

#step{
  background-color: rgba(255, 255, 255, 0);
  color:rgb(32, 58, 67);
  border-radius: 20px;
  padding-bottom: 10px;
  box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.2);
}


.header{

    color: white;
    padding: 10px;
    height: 60px;
  }

.logout{
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 20px;
  }

  .return{
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 20px;
  }
  

